import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core"
import FWBanner from "../../components/fullWidthBanner"
import FWCard from "../../components/fullWidthCard"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5)
  },
  contactContainer: {
    marginTop: theme.spacing(10)
  },
  formContainer: {
    marginTop: theme.spacing(5)
  },
  mapContainer: {
    marginTop: theme.spacing(5)
  },
  marginTop: {
    marginTop: theme.spacing(5)
  }, sectionWrapper: {
    flexWrap: 'nowrap',
    width: '100%',
    height: '400px',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      height: 'auto'
    }
  },
}))
const Exports = ({ location }) => {
  const classes = useStyles()

  const nodeLocale = location.pathname.split('/')[1] === 'en' ? 'en-EU' : 'tr-TR'

  return (
    <Layout nodeLocale={nodeLocale} location={location}>
      <SEO title="Become Exclusive Distributor in Your Country " lang={nodeLocale} location={location} />
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <FWBanner imgName='export-ana-banner.jpg' title='Get Ready For The Action' subtitle='Earn Truckloads of money' dark 
          actions={[{to:'/en/contact', label:'Get in touch now!'}]}
          />
         
        </Grid>
        <Grid item xs={12}>
          <Grid container className={`${classes.sectionWrapper} ${classes.marginTop}`}>
            <FWCard title='Win Win' subtitle="We aim to establish mutually beneficial, longlasting relationships." image='export-win-win.jpg' position='right' />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={`${classes.sectionWrapper} ${classes.marginTop}`}>
            <FWCard title='The Decisive Advantage: Speed' subtitle="Not tomorrow, not this afternoon but NOW! Quick and ongoing interaction with our Export Management Team, 24/7" image='export-speed.jpg' position='left' />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={`${classes.sectionWrapper} ${classes.marginTop}`}>
            <FWCard title='Agile and responsive' subtitle="We are hyper-focused on the needs of our customers and follow up with our export customers based on the characteristics of their markets." image='export-agile.jpg' position='right' />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={`${classes.sectionWrapper} ${classes.marginTop}`}>
            <FWCard title='Flexible' subtitle="We quickly react to new data and information and adapt as needed." image='export-flexible.jpg' position='left' />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Exports
